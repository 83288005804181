<template>
	<div>
		<div class="ft20 cl-main ftw600">次卡管理</div>
		
		<div class="mt20">
			<div class="form-search-box ">
				<a-form layout="inline">
					<a-form-item label="次卡名称">
						<a-input v-model="search.name" placeholder="请输入次卡名称"></a-input>
					</a-form-item>
					<a-form-item  v-if="shop_id==0" label="站点">
						<a-input v-model="search.shop_name" placeholder="请输入站点名称"></a-input>
					</a-form-item>
			
					<a-form-item>
						<a-button @click="searchAct" type="primary">查询</a-button>
						<a-button @click="cancelAct" class="ml10">取消</a-button>
					</a-form-item>
				</a-form>
			</div>
		</div>
		
		<div class="mt20">
			<div class="pd30 bg-w " style="min-height: 800px;">
					<div>
						<a-button type="primary" icon="plus" @click="addSnapUpAct()">添加次卡</a-button>
					</div>
					
					<div class="mt20 flex alcenter">
						<a-radio-group v-model="search.status" button-style="solid" @change="changeStatus">
							<a-radio-button :value="1">
							  上架中
							</a-radio-button>
							<a-radio-button :value="-1">
							  已下架
							</a-radio-button>
						</a-radio-group>
						
						<div class="ml40 flex alcenter">
							<div>是否秒杀</div>

							<a-radio-group name="radioGroup" class="ml15" v-model="search.is_flash_sale" @change="changeFlash">
								<a-radio :value="3">
									全部
								</a-radio>
							    <a-radio :value="1">
							      是
							    </a-radio>
							    <a-radio :value="0">
							      否
							    </a-radio>
							</a-radio-group>
						</div>
					</div>
					
					<div class=" mt20">
						<div class="wxb-table-gray">
							<a-table rowKey="once_card_id" :columns="columns" :pagination="pagination" @change="handleTableChange"
								:data-source="datas" :loading="loading">
								
								<div  slot="oncecard" slot-scope="record">
									<div class="flex left" >
										<img :src="record.cover_img"  class="projetc-index-cover-img" />
										<div class="ml5" style="text-align: left;">
											<div>{{record.name}}</div>
										</div>
									</div>
								</div>
								<div class="flex left" slot="shop" slot-scope="shop,index">
									<a-tag v-if="shop.name" color="purple">
										{{ shop.name }}
									</a-tag>
									<a-tag v-else color="green">平台</a-tag>
								</div>
								<div  slot="flash_sale" slot-scope="record">
									<div class="flex alcenter" v-if="record.is_flash_sale==1 && record.is_flash_sale_expire==0">
										<div class="once-card-flash-sale-tag ft14 ftw400 cl-w" style="background: #FF6600; text-align: center">秒杀</div>
										<div class="ml10 ft14 ftw400 cl-black ft12">秒杀价 ¥{{record.flash_price}}</div>
									</div>
									<div class="flex alcenter" v-else>
										<div class="once-card-flash-sale-tag ft14 ftw400 cl-w" style="background: #DCDFE6;text-align: center">秒杀</div>
									</div>
								</div>
								
								<div class="right" slot="price" slot-scope="price,record">
									¥{{price}}
								</div>
								
								<div class="flex right" slot="items_count" slot-scope="items_count,record">
									{{items_count}}个(总价值¥{{record.worth}})
								</div>
			
								<template slot="action"  slot-scope="record,index">
									<div class="flex center">
										<a-dropdown placement="bottomRight">
											<span class="more-act">
												<i class="iconfont iconmore_gray"></i>
											</span>
											<a-menu slot="overlay">
												<a-menu-item>
													<a class="menu-act" href="javascript:;" @click="editSnapUpAct(record)">
														<i class="iconfont ft14 iconedit"></i>
														<span class="ml10">编辑</span>
													</a>
												</a-menu-item>
												<a-menu-item v-if="record.status==1">
													<a class="menu-act" href="javascript:;" @click="undercarriage(record)">
														<i class="iconfont ft14 iconxiajia"></i>
														<span class="ml10">下架</span>
													</a>
												</a-menu-item>
												<a-menu-item v-if="record.status==-1">
													<a class="menu-act" href="javascript:;" @click="grounding(record)">
														<i class="iconfont ft14 iconshangjia"></i>
														<span class="ml10">上架</span>
													</a>
												</a-menu-item>
												<a-menu-item v-if="record.status==-1">
													<a class="menu-act" href="javascript:;" @click="del(record)"> 
														<i class="iconfont ft14 icondelete"></i>
														<span class="ml10">删除</span>
													</a>
												</a-menu-item>
			
											</a-menu>
										</a-dropdown>
									</div>
								</template>
							</a-table>
						</div>
					</div>
				</div>
			
		</div>
	</div>
</template>

<script>
	import {listMixin} from '../../common/mixin/list.js';
	export default{
		mixins: [listMixin],
		data(){
			return{
				loading: false,
				shop_id:1,
				pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
				},
				search:{
					name:'',
					status:1,
					is_flash_sale:3,
                    shop_name:'',
				},
				columns: [
					
					// {title: '编号',dataIndex: 'once_card_id',align: 'center'},
					// {title: '次卡',key: 'oncecard',align: 'center',width:300,scopedSlots: {customRender: 'oncecard'}},
					// {title: '是否秒杀',key: 'flash_sale',align: 'center',width: 200,scopedSlots: {customRender: 'flash_sale'}},
					// {title: '售价',dataIndex: 'price',align: 'center',scopedSlots: {customRender: 'price'}},
					// {title: '已售',dataIndex: 'take_count',align: 'center',ellipsis: true},
					// {title: '关联项目',dataIndex: 'items_count',align: 'center',scopedSlots: {customRender: 'items_count'}},
					// {title: '创建时间',dataIndex: 'add_time_format',align: 'center',ellipsis: true},
					// {title: '操作',key: 'action',align: 'center',scopedSlots: {customRender: 'action'}}
				],
				datas: [],
			}
		},
		methods:{
			getLists() {
				if (this.loading == true) return;
				this.loading = true;
				this.$http.api('admin/getOncecardList', {
					limit: this.pagination.pageSize,
					page: this.pagination.current,
					name: this.search.name,
					status: this.search.status,
                    shop_name: this.search.shop_name,
					is_flash_sale: this.search.is_flash_sale,
				}).then(res => {
					this.pagination.total = res.total;
					this.datas = res.list;
					this.shop_id=res.shop_id;
					this.columns=res.columns;
					this.loading = false;
				}).catch(res => {
					this.loading = false;
				})
			},
			undercarriage(record){
				this.$confirm({
					title:'确认下架这个次卡吗？',
					okText:"确定",
					okType:"danger",
					cancelText:"取消",
					onOk : ()=>{
						return new Promise((resolve,reject)=>{
							this.$http.api('admin/undercarriageOncecard',{
								once_card_id:record.once_card_id,
							}).then(res=>{
								this.$message.success('下架成功');
								this.getLists();
							}).catch(res=>{
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					}
				})
			},
			
			grounding(record){
				this.$confirm({
					title:'确认上架这个次卡吗？',
					okText:"确定",
					okType:"danger",
					cancelText:"取消",
					onOk : ()=>{
						return new Promise((resolve,reject)=>{
							this.$http.api('admin/groundingOncecard',{
								once_card_id:record.once_card_id,
							}).then(res=>{
								this.$message.success('上架成功');
								this.getLists();
							}).catch(res=>{
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					}
				})
			},
			
			del(record){
				this.$confirm({
					title:'确认删除这个次卡吗？',
					okText:"确定",
					okType:"danger",
					cancelText:"取消",
					onOk : ()=>{
						return new Promise((resolve,reject)=>{
							this.$http.api('admin/delOncecard',{
								once_card_id:record.once_card_id,
							}).then(res=>{
								this.$message.success('删除成功');
								this.getLists();
							}).catch(res=>{
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					}
				})
			},
			
			changeFlash(){
				this.pagination.current=1;
				this.getLists();
			},
			
			addSnapUpAct(){
				this.$router.push('/project/oncecard/edit');
			},
			editSnapUpAct(record){
				this.$router.push('/project/oncecard/edit?id='+record.once_card_id);
			},
			
			changeStatus(){
				this.pagination.current=1;
				this.getLists();
			},
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
		}
	}
</script>

<style>
	.projetc-index-cover-img{
		width: 60px;
		height: 40px;
	}
	
	.once-card-flash-sale-tag{
		width: 40px;
		height: 20px;
		border-radius: 3px;
	}
</style>
